import { PropAlarmLogs, PropsSaveConfigsInMachine } from './globalsConfigs'

class ModulesDevices {
  getValueProp_mode_operation() {
    return window.controlID.modules.devices.mode_operation
  }

  setValueProp_mode_operation(value: string) {
    window.controlID.modules.devices.mode_operation = value
  }

  reseteValueProp_mode_operation() {
    window.controlID.modules.devices.mode_operation = '1'
  }

  getMultiFactorAuthentication() {
    return window.controlID.modules.devices.advancedDeviceConfig.generalConfig.multiFactorAuthentication
  }

  setMultiFactorAuthentication(value: null | boolean) {
    window.controlID.modules.devices.advancedDeviceConfig.generalConfig.multiFactorAuthentication = value
  }

  resetMultiFactorAuthentication() {
    window.controlID.modules.devices.advancedDeviceConfig.generalConfig.multiFactorAuthentication = null
  }

  getValuesConfigDevices() {
    return window.controlID.modules.devices.configDevices
  }

  setValuesConfigDevices({ prop, value }: {
    prop: 'ADE' | 'modeOperation' | 'periodSeconds',
    value: string | boolean
  }) {
    window.controlID.modules.devices.configDevices[prop] = value
  }

  resetValuesConfigDevices() {
    window.controlID.modules.devices.configDevices = {
      ADE: false,
      periodSeconds: '0',
      modeOperation: '1'
    }
  }
}

class ModulesConfigCulture {
  getValues() {
    return window.controlID.modules.configCulture
  }

  setValues(values: PropsSaveConfigsInMachine) {

    window.controlID.modules.configCulture = values
  }

  resetValues() {
    window.controlID.modules.configCulture = {
      countryCode: "+55",
      countryDevices: [],
      description: "BR",
      id: 0,
      privacyPolicy: "https://idsecure.com.br/media/politicaPrivacidade.pdf",
      remoteAccess: "https://get.teamviewer.com/6spb2bi",
      termsOfService: "https://idsecure.com.br/media/2022.12.02%20-%20Acordo%20de%20Licen%C3%A7a%20Para%20Usu%C3%A1rio%20Final%20(EULA)_v2_clean.pdf"
    }
  }

  getDevicesIncludesInTenantLocal() {
    const listDevices = window.controlID.modules.configCulture.countryDevices



    return listDevices
  }

  getAlarms() {
    const listDevices = window.controlID.modules.configCulture.countryDevices
    const alarms: PropAlarmLogs[] = []
    const alarmsIds: number[] = []

    listDevices.forEach(d => {
      d.alarmLogs.forEach(a => {
        if (!alarmsIds.includes(a.alarmLogCause)) {
          alarms.push(a)
          alarmsIds.push(a.alarmLogCause)
        }

      })
    })


    return {
      alarms,
      alarmsIds
    }
  }

  getTypePhone() {
    switch (window.controlID.modules.configCulture.countryCode) {
      case '+55': {
        return 'br'
      }
      case '+1': {
        return 'us'
      }

      default: {
        return 'br'
      }
    }
  }

  isMaskComponent() {
    if (window.controlID.modules.configCulture.description === "Brasil") {
      return true
    }

    return false
  }

  viewLogin() {
    const language = window.navigator.language

    if (language === 'pt') {
      return {
        privacyPolicy: "https://idsecure.com.br/media/politicaPrivacidade.pdf",
        termsOfService: "https://idsecure.com.br/media/2022.12.02%20-%20Acordo%20de%20Licen%C3%A7a%20Para%20Usu%C3%A1rio%20Final%20(EULA)_v2_clean.pdf"
      }
    }

    return {
      privacyPolicy: "https://idsecure.com.br/media/us_privacy_policy.htm",
      termsOfService: "https://idsecure.com.br/media/eula-en.pdf"
    }
  }
}

const modulesDevices = new ModulesDevices()
const modulesConfigCulture = new ModulesConfigCulture()

export {
  modulesDevices,
  modulesConfigCulture
}