import axios from 'axios';

class CultureService {
  url = process.env.REACT_APP_IDSECURE_API_URL;

  async setCulture(culture: string) {
    return axios.get(`${this.url}/operators/language`, {
      params: {
        culture
      }
    });
  }
}

export const cultureService = new CultureService()