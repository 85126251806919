import cookie from 'js-cookie';
import { IntlShape } from 'react-intl';

interface IsetCustomLabel {
  inputName: string,
  value: string
}
export class CustomLabels {

  setCustomLabel({ inputName, value }: IsetCustomLabel) {

    if (value) {
      cookie.set(`idsegurecustom-${inputName}`, value)
    }
  }

  getCustomLabel({ defaultName, inputName, isTagNew = false, intl }: {
    inputName: string, defaultName: string, isTagNew?: boolean, intl: IntlShape
  }) {
    const name = cookie.get(`idsegurecustom-${inputName}`)

    if (!name || name === null) return defaultName

    if (isTagNew) {
      return `${intl.formatMessage({ id: 'NEW' })} ${name}`
    }

    return `${name}`
  }

  verifyIsCustomLabel(inputName: string) {
    const customName = cookie.get(`idsegurecustom-${inputName}`)

    if (!customName || customName === null) return false

    return true
  }

  customLabelReports(data: { intl: IntlShape, inputName: string }) {
    const { inputName, intl } = data
    const customName = cookie.get(`idsegurecustom-${inputName}`)
    return `${intl.formatMessage({ id: 'ACCESS.TO' })} ${customName}`
  }


}


export const customLabels = new CustomLabels()