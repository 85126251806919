import { modulesConfigCulture } from './modulesControll'

export type PropAlarmLogs = {
  alarmLogCause: number,
  strAlarmLogCause: string
}

export type PropCountryDevices = {
  deviceModelType: number,
  strDeviceModelType: string,
  alarmLogs: PropAlarmLogs[]
}

export type PropsSaveConfigsInMachine = {
  countryCode: string,
  countryDevices: PropCountryDevices[],
  description: string,
  id: number,
  privacyPolicy: string,
  remoteAccess: string,
  termsOfService: string
}

class GlobalConfigs {
  private secureCode = "@idsecure_module_culture@"

  saveConfigsInMachine(props: PropsSaveConfigsInMachine) {
    const valuesToString = JSON.stringify(props)
    localStorage.setItem(this.secureCode, valuesToString)
  }

  loadConfigsInMachine() {
    const valuesString = localStorage.getItem(this.secureCode)

    if (!valuesString) return

    const values: PropsSaveConfigsInMachine = JSON.parse(valuesString)

    this.setConfigsGlobals(values)

  }

  setConfigsGlobals(values: PropsSaveConfigsInMachine) {
    modulesConfigCulture.setValues(values)
  }
}


export const globalConfigs = new GlobalConfigs()

