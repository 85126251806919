type IPROPS = {
  type: string;
  value: string;
};

type IMODULES = {
  id: number;
  permission: number;
};

function textToLevel(value: string) {
  switch (value) {
    case 'NoAccess':
      return 1;
    case 'ReadandWrite':
      return 3;
    default:
      return 2;
  }
}

function convertTypePermissions(data: IPROPS[]) {
  const modules: IMODULES[] = [];
  const functionalities: any = [];

  data.forEach((value) => {
    switch (value.type) {
      case 'PLANTDOWN': {
        modules.push({
          id: 2,
          //permission: 2
          permission: textToLevel(value.value)
        });

        return;
      }

      case 'REGISTER': {
        modules.push({
          id: 3,
          permission: textToLevel(value.value)
        });

        return;
      }

      case 'ACCESS': {
        modules.push({
          id: 4,
          permission: textToLevel(value.value)
        });

        return;
      }

      case 'REPORTS': {
        modules.push({
          id: 5,
          permission: textToLevel(value.value)
        });

        return;
      }

      case 'CONFIGS': {
        modules.push({
          id: 6,
          // permission: 1
          permission: textToLevel(value.value)
        });

        return;
      }

      case 'DASHBOARD': {
        modules.push({
          id: 1,
          permission: textToLevel(value.value) === 1 ? 4 : 3
        });

        return;
      }

      case 'ACCESS.MONITORING': {
        modules.push({
          id: 7,
          permission: textToLevel(value.value)
        });

        return;
      }

      case 'VEHICLE.ACCESS.MONITORING': {
        modules.push({
          id: 9,
          permission: textToLevel(value.value)
        });

        return;
      }

      case 'ALARM.MONITORING': {
        modules.push({
          id: 8,
          permission: textToLevel(value.value)
        });

        return;
      }

      case 'CONFIG.SYSTEM': {
        functionalities.push({
          id_module: 6,
          id: 11,

          permission: textToLevel(value.value)
        });

        return;
      }

      case 'CONFIG.PROFILES': {
        functionalities.push({
          id_module: 6,
          id: 12,
          permission: textToLevel(value.value)
        });

        return;
      }

      case 'REPORTS.REGISTERS': {
        functionalities.push({
          id_module: 5,
          id: 10,
          permission: textToLevel(value.value)
        });

        return;
      }

      case 'REPORTS.ACCESS': {
        functionalities.push({
          id_module: 5,
          id: 9,
          permission: textToLevel(value.value)
        });

        return;
      }

      case 'ACCESS.ACRULES': {
        functionalities.push({
          id_module: 4,
          id: 8,
          permission: textToLevel(value.value)
        });

        return;
      }

      case 'ACCESS.VEHICLERULES': {
        functionalities.push({
          id_module: 4,
          id: 24,
          permission: textToLevel(value.value)
        });

        return;
      }

      case 'ACCESS.SCHEDULES': {
        functionalities.push({
          id_module: 4,
          id: 7,
          permission: textToLevel(value.value)
        });

        return;
      }

      case 'REPORTS.AUDITLOG': {
        functionalities.push({
          id_module: 5,
          id: 13,
          permission: textToLevel(value.value)
        });

        return;
      }

      case 'REPORTS.PERMISSION': {
        functionalities.push({
          id_module: 5,
          id: 14,
          permission: textToLevel(value.value)
        });

        return;
      }

      case 'ACCESS.DEVICES': {
        functionalities.push({
          id_module: 4,
          id: 6,
          permission: textToLevel(value.value)
        });

        return;
      }

      case 'ACCESS.AREAS': {
        functionalities.push({
          id_module: 4,
          id: 5,
          permission: textToLevel(value.value)
        });

        return;
      }

      case 'REGISTER.COMPANYS': {
        functionalities.push({
          id_module: 3,
          id: 4,
          permission: textToLevel(value.value)
        });

        return;
      }

      case 'REGISTER.GROUP_PERSON': {
        functionalities.push({
          id_module: 3,
          id: 3,
          permission: textToLevel(value.value)
        });

        return;
      }

      case 'REGISTER.VISITORS': {
        functionalities.push({
          id_module: 3,
          id: 2,
          permission: textToLevel(value.value)
        });

        return;
      }

      case 'REGISTER.PERSON': {
        functionalities.push({
          id_module: 3,
          id: 1,
          permission: textToLevel(value.value)
        });

        return;
      }

      case 'ACCESS.NOTIFICATIONS': {
        functionalities.push({
          id_module: 4,
          id: 15,
          permission: textToLevel(value.value)
        });

        return;
      }

      case 'ACCESS.CREDIT_TYPE': {
        functionalities.push({
          id_module: 3,
          id: 16,
          permission: textToLevel(value.value)
        });

        return;
      }

      case 'REGISTER.CREDIT_MANAGEMENT': {
        functionalities.push({
          id_module: 3,
          id: 17,
          permission: textToLevel(value.value)
        });

        return;
      }

      case 'REGISTER.TEMPORARY_CARDS': {
        functionalities.push({
          id_module: 3,
          id: 18,
          permission: textToLevel(value.value)
        });

        return;
      }

      case 'REGISTER.VEHICLES': {
        functionalities.push({
          id_module: 3,
          id: 19,
          permission: textToLevel(value.value)
        });

        return;
      }

      case 'REGISTER.PARKING': {
        functionalities.push({
          id_module: 3,
          id: 20,
          permission: textToLevel(value.value)
        });

        return;
      }

      case 'ACCESS.SCHEDULED_INACTIVATION': {
        functionalities.push({
          id_module: 4,
          id: 21,
          permission: textToLevel(value.value)
        });

        return;
      }

      case 'ACCESS.DEVICE_NOTIFICATIONS': {
        functionalities.push({
          id_module: 4,
          id: 22,
          permission: textToLevel(value.value)
        });

        return;
      }

      case 'REPORTS.CURRENT_STATUS': {
        functionalities.push({
          id_module: 5,
          id: 23,
          permission: textToLevel(value.value)
        });

        return;
      }

      default: {
        return;
      }
    }
  });

  return { modules, functionalities };
}

export { convertTypePermissions };
