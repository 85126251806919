import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Container, Modal, Row, Table } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import { UPLOAD_CSV, uploadFilePostApi } from 'src/services/downloadCSV';
import exemplo_person from './exemplo_pessoas.csv';
import exemplo_visitant from './exemplo_visitantes.csv';

import { execToast } from './Toast';

import { setTimeout } from 'timers';
import { Loading } from 'src/_metronic/layout/components/Loading';
import { ToggleSwitch } from './forms/ToggleSwitch';

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};
export function PersonAndVisitorsUpload(props: any) {
  const history = useLocation()
  const currentModule = history.pathname

  const [isLoading, setIsLoading] = useState(false)

  const [remoteLink, setRemoteLink] = useState(false)
  const [qrcode, setQrCode] = useState(false)

  const intl = useIntl();
  const [file, setFile] = useState('');
  const [fileName, setFileName] = useState('');
  const [switchSectionContent, setSwitchSectionContent] = useState(false);


  const [viewBodyModal, setViewBoryModal] = useState<'file' | 'table'>('file')
  const [tableBodyValues, setTableBodyValues] = useState<{
    id: number,
    nameInDb: string,
    nameInList: string
  }[]>([])

  const [tableValuesInError, setTableValuesInError] = useState<any[]>([])

  const saveFile = (e: any) => {
    setFile(e);
    setFileName(e.name);
  };

  const uploadFile = async (confirm = false) => {
    setIsLoading(true)
    setViewBoryModal('table')

    setSwitchSectionContent(false);
    // props.onHide()
    execToast('success', intl.formatMessage({ id: 'PERSONS.FINGERPRINTS.MODAL.PROCESSING' }), 3000);

    try {

      const path = currentModule.includes('/people') ? '/persons/import' : '/visitors/import'
      const retorno = await UPLOAD_CSV(confirm, file, fileName, confirm, path, remoteLink, qrcode);

      setTimeout(async () => {
        if (confirm) {
          execToast('success', intl.formatMessage({ id: 'IMPORT.CONFIRM.TITLE' }), 3000);

          if (currentModule.includes('/people')) {
            (window as any).personRequest()
          } else {
            (window as any).visitorsRequest()
          }
          props.onHide();
          return
        } else {
          setViewBoryModal('table')



          if (retorno.data && retorno?.data.data) {

            if (Array.isArray(retorno.data.data) && retorno.data.data.length === 0) {
              execToast('success', intl.formatMessage({ id: 'IMPORT.CONFIRM.TITLE' }), 3000);

              if (currentModule.includes('/people')) {
                (window as any).personRequest()
              } else {
                (window as any).visitorsRequest()
              }
              props.onHide();
            }
            // console.log({ a: retorno?.data.data })
            setTableBodyValues(retorno?.data.data)
            const importErrors = retorno?.data.data.filter((i: any) => i.error)


            setTableValuesInError(importErrors)
          } else {



            if (retorno.data === '' || retorno.data.length === 0) {
              execToast('success', intl.formatMessage({ id: 'IMPORT.CONFIRM.TITLE' }), 3000);

              if (currentModule.includes('/people')) {
                (window as any).personRequest()
              } else {
                (window as any).visitorsRequest()
              }
              props.onHide();
            } else {
              setTableBodyValues([])
              errorInImport(retorno)
            }


          }
        }
        setIsLoading(false)
      }, 1000)

    } catch (ex) {
      setIsLoading(false)
      setViewBoryModal('file')
      // props.onHide();
      execToast('error', intl.formatMessage({ id: 'TOAST.UPLOAD.ERROR' }), 3000);
      console.error(ex);
    }
  };

  function errorInImport(retorno: any) {
    // console.log(retorno)

    if (!retorno.response) {
      return
    }

    if (retorno.response.status === 422) {
      const message = retorno.response.data.message

      if (typeof message === "string" && message.includes('461')) {
        execToast('error', intl.formatMessage({ id: 'TOAST.PERSON.ERROR.454' }) + message.split('|').filter((_: string, index: number) => index > 0), false);
      }

      if (typeof message === "string" && message.includes('462')) {
        execToast('error', message.split('|').filter((_: string, index: number) => index > 0) + ' ' + intl.formatMessage({ id: 'TOAST.PERSON.ERROR.462' }), false);
      }

      setViewBoryModal('file')
    }

    const msg = retorno.response.data.message
    if (msg === "453") {
      execToast('error', intl.formatMessage({ id: 'TOAST.PERSON.DOCUMENT' }), false);
      setViewBoryModal('file')
      return
    }
  }



  const onDrop = useCallback((acceptedFiles: any[]) => {
    saveFile(acceptedFiles[0]);
    setFileName(acceptedFiles[0].name);
    setSwitchSectionContent(true);
  }, []);




  return (
    <Modal
      {...props}
      aria-labelledby='contained-modal-title-vcenter'
      size='lg'
      onHide={() => {

        props.onHide();
      }}>
      <Modal.Header closeButton={true}>
        <Modal.Title id='contained-modal-title-vcenter'>
          {currentModule.includes('/people')
            ? intl.formatMessage({ id: 'IMPORT.PERSON.TITLE' })
            : intl.formatMessage({ id: 'IMPORT.VISITORS' })
          }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='show-grid'>

        {
          viewBodyModal === 'file' && (
            <Container>

              <Row>
                {switchSectionContent ? (
                  <section className='container' style={{ padding: '0 11px' }}>
                    <h5>{intl.formatMessage({ id: 'IMPORT.PERSON.MODAL.SUCESS-MENSAGE' })}</h5>
                    <p>{fileName}</p>
                  </section>
                ) : (
                  <>
                    <Row>
                      <Col xs={8} md={12} className='p-5'>
                        <h5>{intl.formatMessage({ id: 'IMPORT.PERSON.PRICIPAL.TITLE.CSV' })}</h5>
                        <div className='d-flex justify-content-center'>
                          <a
                            className='col-12'
                            href={currentModule.includes('/people') ? exemplo_person : exemplo_visitant}
                            download={currentModule.includes('/people') ? 'exemplo_pessoas.csv' : 'exemplo_visitantes.csv'}>
                            <Button className='col-12 mt-5'>
                              {intl.formatMessage({ id: 'IMPORT.PERSON.MODAL.EXAMPLE.BUTTON.CSV' })}
                            </Button>
                          </a>
                        </div>
                      </Col>
                    </Row>

                    <section className='container' style={{ padding: '0 11px' }}>
                      <h5>{intl.formatMessage({ id: 'IMPORT.PERSON.SUBMIT.TITLE' })}</h5>
                      <Dropzone
                        onDrop={onDrop}
                        maxFiles={1}
                        multiple={false}
                        maxSize={268435456} //250BM
                        accept={{
                          'application/zip': ['.zip'],
                          'application/octet-stream': ['.zip', '.csv'],
                          'text/csv': ['.csv']
                        }}>
                        {({ getRootProps, getInputProps, isFocused, isDragAccept, isDragReject }) => (
                          <div
                            {...getRootProps({
                              className: 'dropzone',
                              style: {
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                padding: '30px',
                                borderWidth: 2,
                                borderRadius: 2,
                                borderColor: '#eeeeee',
                                borderStyle: 'dashed',
                                backgroundColor: '#fafafa',
                                color: '#bdbdbd',
                                outline: 'none',
                                transition: 'border .24s ease-in-out',
                                ...(isFocused ? focusedStyle : {}),
                                ...(isDragAccept ? acceptStyle : {}),
                                ...(isDragReject ? rejectStyle : {})
                              }
                            })}>
                            <input {...getInputProps()} />
                            <p className='m-0'>{intl.formatMessage({ id: 'IMPORT.PERSON.DRAGDROP' })}</p>
                          </div>
                        )}
                      </Dropzone>
                    </section>
                  </>

                )}
              </Row>
            </Container>
          )
        }

        {
          viewBodyModal === 'table' && (
            <div>


              {
                isLoading ? <Loading /> : (
                  <>
                    <Container>
                      <Row>
                        <h5>{intl.formatMessage({ id: tableValuesInError.length ? 'IMPORT.BODY.H5.CSV.ERROR' : 'IMPORT.BODY.H5.CSV' })}</h5>
                      </Row>
                    </Container>

                    {
                      tableValuesInError.length ? (
                        <Table>
                          <thead>
                            <tr>
                              <th style={{ color: '#B5B5C3', fontWeight: '600', textTransform: 'uppercase' }}>
                                {intl.formatMessage({ id: 'IMPORT.TABLE.COLUMN.ID' })}
                              </th>
                              <th style={{ color: '#B5B5C3', fontWeight: '600', textTransform: 'uppercase' }}>
                                {intl.formatMessage({ id: 'NAME_LABEL' })}
                              </th>
                              <th style={{ color: '#B5B5C3', fontWeight: '600', textTransform: 'uppercase' }}>
                                {intl.formatMessage({ id: 'BADLOGIN_FILTER_ERROS' })}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              tableValuesInError.map(item => (

                                <tr key={item.id}>
                                  <td>{item.id}</td>
                                  <td>{item.name}</td>
                                  <td>{item.error}
                                  </td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </Table>
                      ) : (
                        <Table>
                          <thead>
                            <tr>
                              <th style={{ color: '#B5B5C3', fontWeight: '600', textTransform: 'uppercase' }}>
                                {intl.formatMessage({ id: 'IMPORT.TABLE.COLUMN.ID' })}
                              </th>
                              <th style={{ color: '#B5B5C3', fontWeight: '600', textTransform: 'uppercase' }}>
                                {intl.formatMessage({ id: 'IMPORT.TABLE.COLUMN.CURRENT' })}
                              </th>
                              <th style={{ color: '#B5B5C3', fontWeight: '600', textTransform: 'uppercase' }}>
                                {intl.formatMessage({ id: 'IMPORT.TABLE.COLUMN.UPDATE' })}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              tableBodyValues.map(item => (

                                <tr key={item.id}>
                                  <td>{item.id}</td>
                                  <td>{item.nameInDb}</td>
                                  <td>{item.nameInList}
                                  </td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </Table>
                      )
                    }

                  </>
                )
              }

            </div>
          )
        }



      </Modal.Body>
      <Modal.Footer>

        {
          !currentModule.includes('/people') && (
            <div style={{
              width: '500px',
              display: 'flex',
              alignItems: 'center',
              marginRight: '40px'
            }}
            >
              <div style={{ display: 'flex', alignItems: 'center', }}>
                <ToggleSwitch
                  checked={remoteLink}
                  onChange={(e) => {
                    setRemoteLink(e.target.checked)
                  }}
                />
                <span style={{ display: 'block' }}>
                  {intl.formatMessage({ id: "IMPORT.SEND.EMAIL.LINK" })}
                </span>
              </div>

              <div style={{ display: 'flex', alignItems: 'center', }}>
                <ToggleSwitch
                  checked={qrcode}
                  onChange={(e) => {
                    setQrCode(e.target.checked)
                  }}
                />
                <span style={{ display: 'block' }}>
                  {intl.formatMessage({ id: "IMPORT.SEND.EMAIL.QRCODE" })}
                </span>
              </div>
            </div>
          )
        }


        <Button variant='primary' disabled={!file} onClick={() => {
          uploadFile(viewBodyModal === 'table' ? true : false)
        }}>
          {viewBodyModal === 'table' ? intl.formatMessage({ id: 'IMPORT.BUTTON.CONFIRM' }) : intl.formatMessage({ id: 'PERSON.NEWPERSON_UPLOAD' })}
        </Button>

        <Button
          type='button'
          variant='secondary'
          onClick={() => {

            props.onHide();
          }}>
          {intl.formatMessage({ id: 'PERSON.NEWPERSON_CANCEL_BUTTON' })}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
