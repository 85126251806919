/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react';
import { Switch, Redirect, Link, useHistory } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { ContentRoute, useHtmlClassService } from '../../../../_metronic/layout';
import Login from './Login';
import Registration from './Registration';
import ForgotPassword from './ForgotPassword';
import '../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss';
import SalesChannelLogin from './SalesChannelLogin';

// import politica from './../../../../../public/media/politicaPrivacidade.pdf';
// import logoControlId from '../../../../../public/media/logos/logo.png';
import { useIntl, FormattedMessage } from 'react-intl';
import { modulesConfigCulture } from 'src/utils/modulesControll';

export function AuthPage() {
  const history = useHistory()
  const intl = useIntl();
  const authTitle = intl.formatMessage({ id: 'AUTH.ASIDE.TITLE' });
  const uiService = useHtmlClassService();

  const { privacyPolicy, termsOfService } = modulesConfigCulture.viewLogin()

  const layoutProps = useMemo(() => {
    return {
      brandClasses: uiService.getClasses('brand', true),
      headerLogo: uiService.getLogo(),
      headerBlackLogo: uiService.getLogoDark(),
      headerStickyLogo: uiService.getStickyLogo()
    };
  }, [uiService]);

  function goRelease() {

    history.push('/releases')
  }

  return (
    <>
      <div className='d-flex flex-column flex-root'>
        {/*begin::Login*/}
        <div className='login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white' id='kt_login'>
          {/*begin::Aside*/}
          <div
            className='login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10'
            style={{
              backgroundImage: `url(${toAbsoluteUrl('/media/bg/bg-4.jpg')})`
            }}>
            {/*begin: Aside Container*/}
            <div className='d-flex flex-row-fluid flex-column justify-content-between'>
              {/* start:: Aside header */}

              {/* end:: Aside header */}

              {/* start:: Aside content */}
              <div className='flex-column-fluid d-flex flex-column justify-content-center'>
                <Link to='' className='brand-logo'>
                  <img alt='logo' src={layoutProps.headerLogo} className='bg-width-200' />
                </Link>
                <p className='font-weight-lighter text-white opacity-80'>{authTitle}</p>
              </div>
              {/* end:: Aside content */}

              {/* start:: Aside footer for desktop */}
              <div className='d-none flex-column-auto d-lg-flex justify-content-between mt-10'>
                <div className='opacity-70 font-weight-bold	text-white'>&copy; 2022 Control iD</div>
                <div className='d-flex'>
                  <p className='text-white' style={{ cursor: "pointer" }} onClick={goRelease}>{process.env.REACT_APP_VERSION}</p>
                  <a href={termsOfService} className='text-white ml-10' target='_blank' rel="noopener noreferrer">
                    <FormattedMessage id={'FOOTER.ITEM_USE_LICENSE'} />
                  </a>
                  <a href={privacyPolicy} target='_blank' rel="noopener noreferrer" className='text-white ml-10'>
                    <FormattedMessage id={'FOOTER.ITEM_PRIVACY'} />
                  </a>
                </div>
              </div>
              {/* end:: Aside footer for desktop */}
            </div>
            {/*end: Aside Container*/}
          </div>
          {/*begin::Aside*/}

          {/*begin::Content*/}
          <div className='flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden'>
            {/*begin::Content header*/}

            {/*end::Content header*/}
            {/* <div className="bg-dark-position-center">
                <Link to="" className="brand-logo">
                  <img alt="logo" src={layoutProps.headerBlackLogo} className="bg-width-300 "/>
                </Link>
                <h5 className="" >iD Secure - Acesso ao sistema</h5>
              </div> */}

            {/* begin::Content body */}

            <div className='d-flex flex-column-fluid flex-center mt-30 mt-lg-0'>
              <Switch>
                <ContentRoute path='/auth/login' component={Login} children={null} render={null} />
                <ContentRoute path='/auth/registration' component={Registration} children={null} render={null} />
                <ContentRoute path='/auth/saleschannel/login' component={SalesChannelLogin} children={null} render={null} />
                <ContentRoute path='/auth/forgot-password' component={ForgotPassword} children={null} render={null} />
                <Redirect from='/auth' exact={true} to='/auth/login' />
                <Redirect to='/auth/login' />
              </Switch>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div className='d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5'>
              <div className='text-dark-50 font-weight-bold order-2 order-sm-1 my-2'>&copy; 2022 ControliD</div>
              <div className='d-flex order-1 order-sm-2 my-2'>
                {/* <p className='text-dark-75 text-hover-primary'>v1.0.0 Cliente: Dorotech (Dorotech Solucoes em IoT LTDA)</p> */}
              </div>
            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
