export function createVariables() {
  window.controlID = {
    devices: {
      duration1: 0,
      duration2: 0,
      duration3: 0,
      duration4: 0,
      outputType1: 0,
      outputType2: 0,
      outputType3: 0,
      outputType4: 0,
      faceidentry: 1,
      shouldDetectMask1: false,
      shouldDetectMask2: false,
      isMaskRequired1: false,
      isMaskRequired2: false,
      faceDetectionDistance1: 5,
      faceDetectionDistance2: 5
    },
    personModule: {
      cards: [],
      qrcode: {},
      cardRemote: {}
    },
    modules: {
      devices: {
        mode_operation: '',
        advancedDeviceConfig: {
          generalConfig: {
            multiFactorAuthentication: null
          }
        },
        configDevices: {
          ADE: false,
          modeOperation: '1',
          periodSeconds: ''
        }
      },
      configCulture: {
        countryCode: "+55",
        countryDevices: [],
        description: "BR",
        id: 0,
        privacyPolicy: "https://idsecure.com.br/media/politicaPrivacidade.pdf",
        remoteAccess: "https://get.teamviewer.com/6spb2bi",
        termsOfService: "https://idsecure.com.br/media/2022.12.02%20-%20Acordo%20de%20Licen%C3%A7a%20Para%20Usu%C3%A1rio%20Final%20(EULA)_v2_clean.pdf"
      }
    }
  };
}
