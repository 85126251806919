import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { toColumnsTable } from 'src/utils/toColumnsTable';
import { MOCK_LIST } from '../mocks/list';
import { GetProps, createNewPark, deleteParkById, editPark, getAllParks, getParkById, reativeParkingById } from 'src/services/parkingService';
import { API_DATA_TO_TABLE } from '../types';
import { execToast } from 'src/_metronic/_partials/controls/Toast';
import { useHistory } from 'react-router-dom'

type headerTableType = {
  dataField: string;
  text: string;
  sort: boolean;
  headerAlign: string;
  align: string;
  style: any;
};

type paginationOptionsType = {
  custom: boolean;
  page: number;
  sizePerPage: number;
  totalSize: number;
  pageStartIndex: number;
  paginationSize: number;
  showTotal: boolean;
  sizePerPageList:
  | number[]
  | {
    text: string;
    value: number;
  }[];
};

type CreateProps = {
  name: string,
  spaceAvailable: number,
  constrolSpaceAvailable: boolean,
  blockDoubleEntry: boolean,
  blockDoubleExit: boolean,
  parkingSpaces: any[]
}

interface ParkingContextData {
  tableConfig: {
    columns: headerTableType[];
    loadingDataToTable: boolean;
    optionPagination: paginationOptionsType;
    data: any[];
    alterProps(type: string, props: any): void;
    status: 1 | 2 | 0
  };
  modals: {
    showModalEdit: boolean,
    showModalDelete: boolean,
    showModalErase: boolean,
    itemSelected: {
      id: number;
      name: string
    },
    parkingSelectedToModal(id: number, name: string): void,
    modalToggle(type: 'reactive' | 'delete' | 'erase'): void
  },
  fn: {
    create(props: CreateProps): void
    load(props: string): void
    alterSTatus(props: 0 | 1 | 2): void,
    getPark(id: number): Promise<any>,
    edit(props: { description: string, capacity: number, id: number, constrolSpaceAvailable: boolean, parkingSpaces: any[] }): void
    deletePark(action?: any): void
    reativePark(): void
  }
}

const ParkingContext = createContext<ParkingContextData>({} as ParkingContextData);

export const ParkingProvider: React.FC = ({ children }) => {
  const intl = useIntl();
  const history = useHistory()

  const [loadingDataToTable, setLoadingDataToTable] = useState(false);
  const [paginationsSetup, setPaginationSetup] = useState({
    pagination: 1,
    sizePerPage: 10
  });
  const [totalItensTables, setTotalItensTables] = useState(0)
  const [status, setStatus] = useState<1 | 2 | 0>(1)

  const [modalEditShow, setModalEditShow] = useState(false)
  const [modalDeleteShow, setModalDeleteShow] = useState(false)
  const [modalEraseShow, setModalEraseShow] = useState(false)

  const [itemSelected, setItemSelected] = useState({
    name: '',
    id: 0
  })


  const [tableValues, setTableValues] = useState<any[]>([])



  const tableColumns: headerTableType[] = toColumnsTable({
    intl,
    values: ['PARKING_TABLE__ONE', 'PARKING_TABLE__TWO', 'OPERATOR_ROLE_COLLUM_ACTIONS']
  });

  const optionPagination: paginationOptionsType = {
    custom: true,
    totalSize: totalItensTables,
    sizePerPageList: window.sizePerPageList,
    sizePerPage: paginationsSetup.sizePerPage,
    page: paginationsSetup.pagination,
    pageStartIndex: 1,
    paginationSize: paginationsSetup.sizePerPage,
    showTotal: true
  };

  function alterProps(type: string, props: any) {

    const currentPagination = { ...paginationsSetup }

    if (type === 'pagination') {
      // handlerAlterLimitItens(props.sizePerPage);

      if (props.page !== 0) {
        setPaginationSetup(state => {
          return {
            ...state,
            pagination: props.page,
            sizePerPage: props.sizePerPage
          }
        });
        return
      }


      setPaginationSetup(state => {
        return {
          ...state,
          sizePerPage: props.sizePerPage
        }
      })
    }



  }

  function alterSTatus(value: 1 | 2 | 0) {
    setStatus(value)
  }

  async function create(props: CreateProps) {
    try {



      await createNewPark({
        capacity: props.spaceAvailable,
        description: props.name.trim(),
        unlimitedCapacity: props.constrolSpaceAvailable,
        parkingSpaces: props.parkingSpaces
      })

      sessionStorage.removeItem('@CONTROLID@/parking/edit');

      execToast('success', intl.formatMessage({ id: 'PARKING_SAVE_SUCCESS' }), 3000);

      history.goBack()
    } catch (err) {


      if (err.response.data.message === '442') {
        execToast('error', intl.formatMessage({ id: 'PARKING.A_PARKING_LOT_WITH_THAT_NAME_ALREADY_EXISTS' }), 3000);
        return
      }

      execToast('error', intl.formatMessage({ id: 'PARKING_ADD_ERROR' }), 3000);
    }
  }


  function parkingSelectedToModal(id: number, name: string) {
    setItemSelected({
      id,
      name
    })
  }

  async function deletePark(action: any) {
    const erase = action === 'erase' ? true : false;
    try {
      await deleteParkById(itemSelected.id, erase)
      if (erase) {
        execToast('success', intl.formatMessage({ id: 'TOAST.PERMANENTLY_DELETED.SUCCESS' }), 3000);
      } else {
        execToast('success', intl.formatMessage({ id: 'PARKING_DELETED' }), 3000);
      }
      load('')
      setModalDeleteShow(false)
      setModalEraseShow(false)
    } catch (err) {
      if (erase) {
        execToast('error', intl.formatMessage({ id: 'TOAST.PERMANENTLY_DELETED.ERROR' }), 3000);
      } else {
        execToast('error', intl.formatMessage({ id: 'PARKING_DELETED_ERROR' }), 3000);
      }
    }
  }

  async function reativePark() {
    try {
      await reativeParkingById(itemSelected.id)

      execToast('success', intl.formatMessage({ id: 'OPERATOR_ROLE_.TOAST_ATIVE' }), 3000);
      // setStatus(1)
      load('')
      setModalEditShow(false)
    } catch (err) {
      execToast('error', intl.formatMessage({ id: 'OPERATOR_ROLE_.ERROR_WHEN_REACTIVATING' }), 3000);
    }
  }

  async function edit(props: { description: string, capacity: number, id: number, constrolSpaceAvailable: boolean, parkingSpaces: any[] }) {
    try {

      await editPark({
        capacity: props.capacity,
        description: props.description.trim(),
        id: props.id,
        unlimitedCapacity: props.constrolSpaceAvailable,
        parkingSpaces: props.parkingSpaces
      })
      sessionStorage.removeItem('@CONTROLID@/parking/edit');
      execToast('success', intl.formatMessage({ id: 'PARKING_EDIT_SUCCESS' }), 3000);

      history.goBack()
    } catch (err) {

      if (err.response.data.message === '442') {
        execToast('error', intl.formatMessage({ id: 'PARKING.A_PARKING_LOT_WITH_THAT_NAME_ALREADY_EXISTS' }), 3000);
        return
      }

      execToast('error', intl.formatMessage({ id: 'PARKING_EDIT_ERROR' }), 3000);
    }
  }

  function getPark(id: number) {
    return getParkById(id)
  }

  function modalToggle(typeModal: 'reactive' | 'delete' | 'erase') {


    if (typeModal === 'reactive') {
      setModalEditShow(state => !state)
    }
    if (typeModal === 'delete') {
      setModalDeleteShow(state => !state)
    }
    if (typeModal === 'erase') {
      setModalEraseShow(state => !state)
    }
  }



  function dataApiToTable(data: API_DATA_TO_TABLE[]) {
    return data.map((park: any) => {


      let capacity = 0
      park.parkingSpaces.forEach((item: any) => capacity += item.capacity)
      return {
        id: park.id,
        "0": park.description,
        "1": capacity,
        "2": "",
        isEnabled: park.isEnabled
      }
    })
  }

  async function load(value: string) {
    const propsRequest: GetProps = {
      pageNumber: paginationsSetup.pagination,
      pageSize: paginationsSetup.sizePerPage,
      sortField: 'Id',
      sortOrder: 'desc',
      status,
      value
    }

    const { data: { data } } = await getAllParks(propsRequest)



    const valuesToTable = dataApiToTable(data.data)


    setTotalItensTables(data.total)
    setTableValues(valuesToTable)


  }

  const data: ParkingContextData = {
    tableConfig: {
      columns: tableColumns,
      loadingDataToTable,
      optionPagination,
      data: tableValues,
      alterProps,
      status
    },
    modals: {
      showModalEdit: modalEditShow,
      showModalDelete: modalDeleteShow,
      showModalErase: modalEraseShow,
      modalToggle,
      itemSelected,
      parkingSelectedToModal
    },
    fn: {
      create,
      load,
      alterSTatus,
      getPark,
      edit,
      deletePark,
      reativePark
    }
  };

  useEffect(() => {
    load('')
  }, [paginationsSetup])

  useEffect(() => {
    setPaginationSetup({
      ...paginationsSetup,
      pagination: 1
    })
  }, [status])

  return <ParkingContext.Provider value={{ ...data }}>{children}</ParkingContext.Provider>;
};

export const useParkingProvider = () => {
  const context = useContext(ParkingContext);

  return context;
};
