import { IntlShape } from 'react-intl';

export function GridListColumns(intl: IntlShape) {

  const columns = [
    {
      dataField: 'description',
      headerAlign: 'left',
      align: 'left',
      text: intl.formatMessage({ id: 'CREDITS_TYPE.DESCRIPTION' }),
      sort: true
    },
    {
      dataField: 'maxDailyConsumption',
      headerAlign: 'left',
      align: 'left',
      text: intl.formatMessage({ id: 'CREDITS_TYPE.MAXDAILY' }),
      sort: true
    },
    {
      dataField: 'actions',
      headerAlign: 'right',
      align: 'right',
      text: intl.formatMessage({ id: 'MENU.ACTIONS' }),
      style: {
        minWidth: '100px'
      },
      headerFormatter: () => { }
    }
  ];

  return columns;
}
